<script lang="ts" setup>
import type { ProductReviewItemType } from '~/api/product';

defineProps<{
  review: ProductReviewItemType;
}>();

// TODO: может не нужно
// const dateString = computed(() => {
//   const date = new Date(review.date);
//   const options: Intl.DateTimeFormatOptions = {
//     day: 'numeric',
//     month: 'long',
//     year: date.getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined,
//   };
//   return date.toLocaleDateString('ru-RU', options);
// });
</script>

<template>
  <div class="reviewCard">
    <BaseRating :value="review.rating" readonly />
    <h3 class="reviewCard__title">
      {{ review.title }}
    </h3>
    <p class="reviewCard__description">
      {{ review.text }}
    </p>

    <div v-if="review.nameUser" class="reviewCard__additional">
      <div>
        <span class="reviewCard__name">
          {{ review.nameUser }}
          <SvgoSimpleCheckSolid
            v-if="review.recommend"
            filled
            class="reviewCard__check"
          />
        </span>

        <!--        <span class="reviewCard__info">-->
        <!--          Возраст: {{ review.user.age }}-->
        <!--        </span>-->
        <!--        <span class="reviewCard__info">-->
        <!--          Тип кожи: {{ review.user.skinType }}-->
        <!--        </span>-->
        <!--        <span class="reviewCard__info">-->
        <!--          Проблема: {{ review.user.skinProblem }}-->
        <!--        </span>-->
      </div>

      <!--      <span class="reviewCard__date">-->
      <!--        {{ dateString }}-->
      <!--      </span>-->
    </div>
  </div>
</template>

<style lang="scss">
.reviewCard {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 24px 18px;
  display: flex;
  flex-direction: column;

  @include mq('sm') {
    padding: 24px 20px 15px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 8px;
    margin-bottom: 15px;

    @include mq('sm') {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 0;
    margin-bottom: 14px;
    white-space: pre-wrap;

    @include mq('sm') {
      margin-bottom: 27px;
    }
  }

  &__additional {
    display: flex;
    gap: 14px;
    align-items: flex-end;
    margin-top: auto;
  }

  &__name {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-bottom: 9px;

    @include mq('sm') {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 2px;
    }
  }

  &__check {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
  }

  &__info {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #c0c0c0;
    display: block;

    @include mq('sm') {
      font-size: 12px;
      line-height: 16px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  &__date {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-left: auto;

    @include mq('sm') {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
